import { useCallback, useState } from "react";
import { useSafeLayoutEffect } from "./use-safe-layout-effect";

export const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(undefined);

  const onResize = useCallback(() => {
    setIsTouchDevice("ontouchstart" in window || navigator.maxTouchPoints > 0);
  }, []);

  useSafeLayoutEffect(() => {
    onResize();
    window.addEventListener("resize", onResize, false);

    return () => {
      window.removeEventListener("resize", onResize, false);
    };
  }, []);

  return isTouchDevice;
};
