import { useState } from "react";
import { useSafeLayoutEffect } from "./use-safe-layout-effect";
import { isBrowser } from "utils";

const getInitialState = (query: string, defaultState?: boolean) => {
  // Prevent a React hydration mismatch when a default value is provided by not defaulting to window.matchMedia(query).matches.
  if (defaultState !== undefined) {
    return defaultState || false;
  }

  if (isBrowser) {
    return window.matchMedia(query).matches;
  }

  return false;
};

export const useMediaQuery = (bpVal: number, defaultState?: boolean) => {
  const [isMatch, setIsMatch] = useState(
    getInitialState(`(min-width: ${bpVal}px)`, defaultState)
  );

  useSafeLayoutEffect(() => {
    if (!isBrowser) return;

    let mounted = true;
    const mql = window.matchMedia(`(min-width: ${bpVal}px)`);

    const onChange = () => {
      if (!mounted) {
        return;
      }
      setIsMatch(!!mql.matches);
    };

    mql.addEventListener("change", onChange);
    setIsMatch(mql.matches);

    return () => {
      mounted = false;
      mql.removeEventListener("change", onChange);
    };
  }, [bpVal]);

  return isMatch;
};
