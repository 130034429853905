import { defaultSeo } from "@/config";
import { GtmScript } from "@/utils/analytics";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as Sentry from "@sentry/nextjs";

import { NextPage } from "next";
import { DefaultSeo } from "next-seo";
import { useRouter } from "next/router";
import { ReactElement, ReactNode, useState } from "react";
import { AppProps } from "next/app";
import { useStatsMonitor } from "hooks";
import { useCookieBanner } from "elements";
// import { ContentSecurityPolicy, FeaturePolicy } from "@/components/head";
import RealViewport from "@/components/real-viewport";

if (process.env.NODE_ENV === "production") {
  // Need to ensure that the latest tailwind.config is always in sync on deployment and production
  require("../../public/styles/tailwind.css");
} else {
  require("@/styles/tailwind.css");
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
  theme?: string;
};

type AppPropsWithLayout = {
  Component: NextPageWithLayout & { className?: string };
  pageProps: AppProps & {
    dehydratedState: any;
    className?: string;
  };
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const { cookieConsent } = useCookieBanner({});
  // const isDebug = useDebug();
  useStatsMonitor();
  const router = useRouter();

  // Use the layout defined at the page level
  const getLayout = Component.getLayout ?? ((page) => page);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            keepPreviousData: true,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
          },
        },
        logger: {
          log: (message) => {
            Sentry.captureMessage(message);
          },
          warn: (message) => {
            Sentry.captureMessage(message);
          },
          error: (error) => {
            Sentry.captureException(error);
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <GtmScript consent={cookieConsent?.statistics} />
        {/* {isDebug && (
          <>
            <FeaturePolicy />
            <ContentSecurityPolicy />
          </>
        )} */}
        <DefaultSeo {...defaultSeo} />
        <RealViewport />

        {getLayout(<Component key={router.route} {...pageProps} />)}

        <ReactQueryDevtools initialIsOpen={false} />
      </Hydrate>
    </QueryClientProvider>
  );
}

export default MyApp;
