import { useEffect } from "react";
import { isBrowser } from "utils";
import Stats from "stats.js";
import useDebug from "./use-debug";

export const useStatsMonitor = () => {
  const isDebug = useDebug();

  useEffect(() => {
    if (isBrowser) {
      // use this library only in development
      if (isDebug) {
        (async () => {
          let stats;
          try {
            stats = new Stats();
            stats.domElement.style.cssText =
              "position:fixed;right:0;bottom:100px;z-index:10000";
            document.body.appendChild(stats.domElement);
            const loop = function () {
              stats.update();
              window.requestAnimationFrame(loop);
            };
            window.requestAnimationFrame(loop);
          } catch (e) {
            console.warn("Stats.js could not be loaded.");
          }
          return stats;
        })();
      }
    }
  }, []);
};
