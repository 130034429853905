import Cookies from "js-cookie";
import { useCallback, useState } from "react";

const COOKIE_BANNER_NAME = "site-cookie-consent";

export type CookieConsentProps = {
  // duration
  session: boolean;
  persistent: boolean;
  // purpose
  necessary: boolean;
  preference: boolean;
  statistics: boolean;
  marketing: boolean;
  // provenance
  firstParty: boolean;
  thirdParty: boolean;
};

const explicitSetCookieValue = (value: boolean) => {
  return {
    // duration
    session: value,
    persistent: value,
    // purpose
    necessary: true,
    preference: value,
    statistics: value,
    marketing: value,
    // provenance
    firstParty: value,
    thirdParty: value,
  } as CookieConsentProps;
};

export interface UseCookieBannerProps {
  defaultCookieConsent?: CookieConsentProps;
  options?: {
    expires: number;
  };
}

/**
 * CookieBanner hook that manages all the logic
 * and returns prop getters, state and actions.
 *
 * @param props
 */
export const useCookieBanner = (props: UseCookieBannerProps) => {
  const {
    options = {
      expires: 30,
    },
    defaultCookieConsent = {
      // duration
      session: false,
      persistent: false,
      // purpose
      necessary: true,
      preference: false,
      statistics: false,
      marketing: false,
      // provenance
      firstParty: false,
      thirdParty: false,
    },
  } = props;

  const validCookie = Cookies.get(COOKIE_BANNER_NAME);
  const initialConsent =
    typeof validCookie === "string"
      ? JSON.parse(validCookie)
      : defaultCookieConsent;

  const [cookieConsent, setCookieConsent] = useState(initialConsent);
  const [showCookieSetting, setShowCookieSettings] = useState(false);
  const [cookieSettings, setCookieSettings] = useState(cookieConsent);

  const _updateCookies = useCallback(
    (cookieSettings: typeof defaultCookieConsent) => {
      Cookies.set(COOKIE_BANNER_NAME, JSON.stringify(cookieSettings));
      setCookieConsent(cookieSettings);
    },
    []
  );

  const _acceptAllCookies = () => {
    const newCookieValue = explicitSetCookieValue(true);
    Cookies.set(COOKIE_BANNER_NAME, JSON.stringify(newCookieValue), options);
    setCookieConsent(newCookieValue);
  };

  const _rejectAllCookies = () => {
    const newCookieValue = explicitSetCookieValue(false);
    Cookies.set(COOKIE_BANNER_NAME, JSON.stringify(newCookieValue), options);
    setCookieConsent(newCookieValue);
  };

  const handleAcceptAllCookies = (cb?: Function) => {
    _acceptAllCookies();
    cb ? cb : null;
  };

  const handleDeclineAllCookies = (cb?: Function) => {
    _rejectAllCookies();
    cb ? cb : null;
  };

  const handleCookieSettingsOpen = useCallback(() => {
    setShowCookieSettings(true);
  }, []);

  const handleCookieSettingsClose = useCallback(
    (cb?: (arg0: CookieConsentProps) => void) => {
      setShowCookieSettings(false);

      cb ? cb : _updateCookies(cookieSettings);
    },
    [_updateCookies, cookieSettings]
  );

  const handleCookieUpdate = useCallback(
    (key: string, value: boolean) => {
      setCookieSettings({ ...cookieSettings, [key]: value });
    },
    [cookieSettings]
  );

  return {
    validCookie,
    cookieConsent,
    handleAcceptAllCookies,
    handleDeclineAllCookies,
    handleCookieUpdate,
    handleCookieSettingsOpen,
    handleCookieSettingsClose,
    showCookieSetting,
    setShowCookieSettings,
    setCookieSettings,
    cookieSettings,
  };
};

export type UseCookieBannerReturn = ReturnType<typeof useCookieBanner>;
