import { useMemo } from "react";

export const useDebug = (env = "development") => {
  const isDebug = useMemo(
    () =>
      typeof window !== "undefined"
        ? window.location.href.includes("#debug") ||
          process.env.NODE_ENV === env
        : false,
    [env]
  );
  return isDebug;
};

export default useDebug;
