import { useRef, useState } from "react";
import { throttle } from "throttle-debounce";
import { useSafeLayoutEffect } from "./use-safe-layout-effect";

export function offsetTop(element, accumulator = 0) {
  const top = accumulator + element.offsetTop;
  if (element.offsetParent) {
    return offsetTop(element.offsetParent, top);
  }
  return top;
}

export function offsetLeft(element, accumulator = 0) {
  const left = accumulator + element.offsetLeft;
  if (element.offsetParent) {
    return offsetLeft(element.offsetParent, left);
  }
  return left;
}

interface Rect {
  top: number;
  left: number;
  width: number;
  height: number;
}

/**
 * getboundingclientrect with scrollY sauce if using our lenis smooth scroll
 */
export const useRect = ({ debounce = 1000 } = {}) => {
  const element = useRef(null);

  const [rect, setRect] = useState<Rect>({
    top: undefined,
    left: undefined,
    width: undefined,
    height: undefined,
  });

  const resize = () => {
    if (element.current) {
      setRect((prev) => ({
        ...prev,
        top: offsetTop(element.current),
        left: offsetLeft(element.current),
      }));
    }
  };

  // resize if body height changes
  useSafeLayoutEffect(() => {
    const callback = throttle(debounce, resize);
    const resizeObserver = new ResizeObserver(callback);
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
      callback.cancel({ upcomingOnly: true });
    };
  }, [debounce]);

  const onResizeObserver = ([entry]) => {
    const { width, height } = entry.contentRect;

    setRect((prev) => ({
      ...prev,
      width,
      height,
    }));
  };

  const resizeObserver = useRef(null);

  const setRef = (node) => {
    if (!node || node === element.current) return;

    resizeObserver.current?.disconnect();
    resizeObserver.current = new ResizeObserver(onResizeObserver);

    resizeObserver.current.observe(node);

    element.current = node;
  };

  const getRect = () => {
    return rect;
  };

  return { setRef, rect, getRect };
};
