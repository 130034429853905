import { raf } from "@studio-freight/tempus";
import { useEffect } from "react";

export const useFrame = (callback, priority = 0) => {
  useEffect(() => {
    if (callback) {
      const id = raf.add(callback, priority);

      return () => {
        raf.remove(id);
      };
    }
  }, [callback, priority]);
};
