import { useCallback, useEffect, useState } from "react";
import { isBrowser } from "utils";

const activityEvents = [
  "mousedown",
  "mousemove",
  "keydown",
  "scroll",
  "touchstart",
];

export const useUserEvents = (events = activityEvents) => {
  const [isUser, setIsUser] = useState(false);

  const handleEvent = useCallback(() => setIsUser(true), []);

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    events.forEach((ev) =>
      document.body.addEventListener(ev, handleEvent, { once: true })
    );
  }, []);

  return [isUser, setIsUser];
};
